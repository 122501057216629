import React from 'react';
//import { Navigate } from 'react-router-dom';
import { FaHome } from "react-icons/fa";
import { VscAccount } from "react-icons/vsc";
import { MdReport } from "react-icons/md";
import { BiSupport } from "react-icons/bi";
import { IoNotifications } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { AiOutlineTransaction } from "react-icons/ai";
import { IoMdMenu } from "react-icons/io";

function Sidebar() {

    const chekMenu = ()=>{
        let x = document.getElementById('menu');
        if (x.style.display==="none"){
            x.style.display ="block"
        }else{
            x.style.display ="none";
        }

    }
  return (
    <div >
        <IoMdMenu  className='menu' onClick={()=> chekMenu()} />
        <div id='menu' className='menu-list'>
            
        
            <li className='sidebarlist'>
            <FaHome />
            <a href='./Dashboard'>Dashboard</a>
        </li>
        <li className='sidebarlist'>
            <VscAccount />
            <a href='./Accounts'>Accounts</a>
        </li>
        <li className='sidebarlist'>
             <AiOutlineTransaction />
            <a href='./Notifications'>Transactions
            
            </a>
            <span className='span'>5</span>
        </li>
        <li className='sidebarlist'>
            <MdReport />
            <a href='./Reports'>Reports</a>
        </li>
        <li className='sidebarlist'>
            <BiSupport />
            <a href='./Support'>Support</a>
        </li>
        <li className='sidebarlist'>
            <IoNotifications />
            <a href='./Notifications'>Notifications</a>
        </li>
        
        <li className='sidebarlist'>
            <IoSettings />
            <a href='./Settings'>Settings</a>
        </li>
        </div>
         
        <div className='sidebar'>
         
        <li className='sidebarlist'>
            <FaHome />
            <a href='./Dashboard'>Dashboard</a>
        </li>
        <li className='sidebarlist'>
            <VscAccount />
            <a href='./Accounts'>Accounts</a>
        </li>
        <li className='sidebarlist'>
             <AiOutlineTransaction />
            <a href='./Notifications'>Transactions
            
            </a>
            <span className='span'>5</span>
        </li>
        <li className='sidebarlist'>
            <MdReport />
            <a href='./Reports'>Reports</a>
        </li>
        <li className='sidebarlist'>
            <BiSupport />
            <a href='./Support'>Support</a>
        </li>
        <li className='sidebarlist'>
            <IoNotifications />
            <a href='./Notifications'>Notifications</a>
        </li>
        
        <li className='sidebarlist'>
            <IoSettings />
            <a href='./Settings'>Settings</a>
        </li>
    </div>
    </div>
  )
}

export default Sidebar
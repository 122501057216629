import React from 'react'

function Dashboard() {
  return (
    <div className='sections'>
        
        <div className='dashboard'>
            <div className='card1'>
               Card1
            </div>

            <div className='card1'>
               Card2
            </div>
        </div>



        <div className='dashboard'>
            <div className='card1'>
               Card3
            </div>

            <div className='card1'>
               Card4
            </div>
        </div>


        
       
    </div>

    
  )
}

export default Dashboard

import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Accounts from './components/Accounts';
import Dashboard from './components/Dashboard';
import Sidebar from './components/Sidebar';

function App() {
  return (
    <div className="App">
      <Sidebar />
      <Router>
         <Routes>
              <Route path='/Dashboard'  element={<Dashboard />} />  
               <Route path='/Accounts'  element={<Accounts />} />
         </Routes>
      </Router>
        
         
    </div>
  );
}

export default App;
